<template>
    <transition>
      <div class="contents-wrap">
        <div class="contents-box">
          <div class="conts-row">
            <div class="conts-box">
              <div class="conts-title">
                상태 : {{ title }}
              </div>
            </div>
          </div>
          <div class="conts-row">
              <div class="conts-box">
                <div class="conts-text">
                    {{ message }}
                </div>
              </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import { DxTextBox } from 'devextreme-vue/text-box';
  
  export default {
    components: {
      DxTextBox,
    },
    props: {
        contentData: Object,
    },
    watch: {
    },
    data() {
      return {
        title: '',
        message: '',
        stylingMode: 'outlined', //[outlined, filled, underlined]
      }
    },
    computed: {
    },
    methods: {
        mountedData() {
            const code = this.$_getCode('process_status').find((item) => item.id === this.contentData.processStatusCd)
            this.title = code.codeNm;

            /**
             * @todo : Error message 처리
             * */
            this.message = '@todo : Error message 처리';
        }
    },
    created() {
    },
    mounted() {
        this.mountedData();
    },
    destroyed() {
    },
  }
  </script>
  
  <style scoped>
  .contents-box {
    width: 100%;
    padding: 20px 10px;
    background-color: #FFF;
  }
  .conts-row:not(:last-child) {
    margin-bottom: 30px;
  }
  .conts-row .conts-box {
    display: inline-block;
  }
  .conts-row .conts-box>div {
    display: inline-block;
  }
  .conts-row .conts-box .conts-title {
    width: 100%;
    font-weight: 500;
    color: #444444;
    font-size: 14px;
  }

  .conts-row .conts-box {
    display: -webkit-inline-box;
  }
  
  .conts-row .conts-box {
    margin-left: 5px;
    display: inline-block;
  }
  .conts-text{
    width: 490px;
    height: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    white-space: normal;
    color: #444444;
    padding: 10px;
    //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  </style>